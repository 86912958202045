import { render, staticRenderFns } from "./UserProfile.vue?vue&type=template&id=7e2e5056"
import script from "./UserProfile.vue?vue&type=script&lang=js"
export * from "./UserProfile.vue?vue&type=script&lang=js"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports